<template>
  <div>
    <div class="box">
        <div class="box_list">
            <ul class="shengming">
                <li @click="to_AboutUs">关于我们</li>
                <li @click="to_TermsService">服务条款</li>
                <li @click="to_law">法律声明</li>
                <li @click="to_business">商务合作</li>
                <li @click="to_agency">合作代理</li>
                <li @click="to_contactUs">联系我们</li>
                <li @click="to_HelpCenter">帮助中心</li>
                <li @click="to_BanQuan">版权说明</li>
                <!-- <li>人才招聘</li> -->
            </ul>
            <ul class="pel">
                <li><i class="el-icon-phone"></i>  联系电话:19143999964</li>
                <li><i class="el-icon-message"></i>  公司邮箱:19143999964@163.com</li>
            </ul>
            <p class="dizhi">公司地址: 海南省海口市龙华区滨海大道32号复兴城D2区1楼-1284</p>
        </div>
    </div>
    <div class="bottom">
        <div class="bottom_box">
            <a href="https://beian.miit.gov.cn" target="_blank" class="beian">CopyRight © 版权所有：海南新智造科技有限公司 | 琼ICP备2022015591号</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            
        }
    },
    methods: {
        //跳转个人中心
        to_HelpCenter(){
            this.$router.push("/HelpCenter")
        },
        //版权说明
        to_BanQuan(){
            this.$router.push("/copyright")
        },
        //联系我们
        to_contactUs(){
            this.$router.push("/ContactUs")
        },
        //合作代理
        to_agency(){
            this.$router.push("/agency")
        },
        //跳转商务
        to_business(){
            this.$router.push("/business")
        },
        //跳转关于我们
        to_AboutUs(){
            this.$router.push("/AboutUs")
        },
        //服务条款
        to_TermsService(){
            this.$router.push("/TermsService")
        },
        //跳转法律
        to_law(){
            this.$router.push("/law")
        }
    }
}
</script>

<style scoped lang="scss">
.box{
    width: 100%;
    height: 250px;
    background-color: #2a2d32;
    margin: auto;
    .box_list{
        width: 1200px;
        margin: auto;
        text-align: center;
        .shengming{
            display: flex;
            justify-content: space-between;
            padding: 30px 0px;
            list-style: none;
            color: white;
            li{
                cursor: pointer;
            }
        }
        .pel{
            display: flex;
            justify-content: space-between;
            width: 600px;
            margin:0px auto;
            padding: 30px;
            color: white;
            li{
                list-style: none;
                flex: 1;
            }
        }
        .dizhi{
            width: 500px;
            color: white;

            margin: auto;
        }
    }
   
}
.bottom{
    width: 100%;
    height: 70px;
    background-color: #191b1e;
    .bottom_box{
        width: 1200px;
        margin: auto;
        text-align: center;
        .beian{
            text-decoration: none;
            width: 600px;
            line-height: 70px;
            height: 70px;
            margin: auto;
            text-align: center;
            color: white;
        }
    }
}
</style>